.project-page {
  text-align: center;
  animation: button-top 1s ease-in-out;

  img {
    max-width: 300px;
  }

  &--content {
    display: flex;
    flex-direction: row;
    margin-top: 100px;
    width: 100%;
    height: 100%;
    text-align: start;

    &--colum1 {
      width: 100%;
      padding: 0 80px;

      h3 {
        font-size: 45px;
        border-bottom: 2px solid $first-color;
        width: fit-content;
        padding-right: 60px;
        margin: 0;
      }

      p {
        font-size: 25px;
        font-weight: 300;
      }

      &--skills {
        margin-top: 60px;
      }

      &--imgs {
        margin-top: 60px;
        &-container {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          margin: 30px 0;
        }
        .img-container {
          margin: 0 30px 15px 0;
          position: relative;
          i {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 50px;
            color: rgba(255, 255, 255, 0.669);
            background: rgba(0, 0, 0, 0.612);
            padding: 20px 15px;
            border-radius: 100%;
            &:hover {
              color: rgba(255, 255, 255, 0.815);
              background: rgba(0, 0, 0, 0.785);
              cursor: pointer;
            }
          }
        }
        img {
          width: 200px;
          height: 150px;
          border: 2px solid $first-color;
          object-fit: cover;
          box-shadow: $first-color 0px 4px 12px;
          border-radius: 10px;
          cursor: pointer;
        }
      }
    }

    &--infos {
      min-width: 240px;
      padding-left: 20px;

      &--links {
        margin-top: 50px;
        display: flex;
        justify-content: space-around;
        &--icon {
          width: 55px;
          height: 55px;
          background-color: rgba(0, 0, 0, 0.514);
          position: relative;
          border-radius: 100%;
          transition: all 0.2s ease;
          margin: 0 10px 10px 0;
          i {
            color: rgba(255, 255, 255, 0.623);
            font-size: 38px;
            position: absolute;
            left: 50%;
            top: 50%;
            transition: all 0.2s ease;
            transform: translate(-50%, -50%);
          }

          &:hover {
            background-color: rgba(0, 0, 0, 0.846);
            transform: scale(1.03);
            i {
              color: white;
            }
          }
        }
      }
    }
  }
}

.infos-sections {
  margin-bottom: 50px;
  h4 {
    font-size: 30px;
    border-bottom: 2px solid $first-color;
    width: fit-content;
    padding-right: 30px;
    margin: 0 0 30px 0;
  }

  &--icons {
    display: flex;
    max-width: 150px;
    flex-wrap: wrap;
    img {
      width: 55px;
      margin: 0 10px 10px 0;
    }
  }
}

@include mobile-only {
  .project-page {
    padding-top: 35px;
    &--logo {
      max-width: 90%;
    }
    &--content {
      flex-direction: column;
      margin-top: 40px;
      padding: 0 10px 10px 10px;
      width: 92%;

      &--colum1 {
        padding: 0;
        h3 {
          font-size: 35px;
        }
        .img-container {
          margin: 0 5px 5px 0;
          img {
            width: 90px;
            height: 70px;
          }
          i {
            font-size: 35px;
            padding: 15px;
          }
        }
      }
      &--infos {
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 50px;
      }
    }
  }
  .infos-sections {
    margin-right: 25px;
  }
}
