.notfound-page {
  position: relative;
  &--svg-container {
    width: 60vw;
    height: 90vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -10%);
    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@include tablet-only {
  .notfound-page--svg-container {
    width: 100%;
  }
}
