@keyframes button-top {
  from {
    transform: translateY(150%);
    opacity: 0;
  }
}

@keyframes ErrorShake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}
