.projects-page {
  .not-projects-found {
    font-size: 25px;
    text-align: center;
  }
  &--filters {
    animation: button-top 1s ease-in-out;

    &-langages,
    &-tools {
      user-select: none;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: 10px 0;
      p {
        font-size: 25px;
        border: 2px solid $first-color;
        padding: 3px 10px;
        border-radius: 30px;
        background-color: #3cb49a22;
        color: $second-color;
        margin: 3px;
        font-weight: 700;
        @include desktop-only {
          &:hover {
            background-color: #3cb49a6e;
            cursor: pointer;
          }
        }
        &:hover {
          cursor: pointer;
        }
        &.active {
          background-color: #3cb49abd;
        }
      }
    }

    &-tools {
      p {
        background-color: rgba(255, 166, 0, 0.114);
        border: 2px solid orange;
        @include desktop-only {
          &:hover {
            background-color: rgba(255, 166, 0, 0.508);
          }
        }

        &.active {
          background-color: rgba(255, 166, 0, 0.796);
        }
      }
    }
  }
  &--content {
    animation: button-top 1s ease-in-out;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 20px;
    &--proj-item {
      width: 330px;
      height: 220px;
      background: radial-gradient(#ffffff48, #12925048, $first-color);
      box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
        rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
      border-radius: 30px;
      margin: 10px;
      position: relative;

      &:hover {
        box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
          rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
          rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        transform: scale(1.01);
      }

      &--img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 90%;
        max-height: 70%;
      }
      &--project-type {
        position: absolute;
        top: 20px;
        left: 20px;
        font-size: 20px;
        margin: 0;
        background-color: rgb(194, 135, 229);
        padding: 5px 8px;
        border-radius: 5px;
        color: black;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
          rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
      }

      &--langages {
        position: absolute;
        width: 100%;
        height: 16%;
        right: 10px;
        bottom: 10px;
        display: flex;
        flex-direction: row-reverse;

        &--icon {
          margin-left: 5px;
        }
      }
    }
  }
}

@include mobile-only {
  .projects-page {
    &--filters {
      p {
        font-size: 20px;
      }
    }
    &--content {
      &--proj-item {
        height: 180px;
      }
    }
  }
}
