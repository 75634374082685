.mobile-header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;

  &-btn {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    border: 1px solid $first-color;
    width: 120px;
    border-radius: 32px;
    background-color: rgba(18, 146, 80, 0.282);
    position: absolute;
    right: 10px;
    top: 10px;
    justify-content: space-between;
    i {
      margin: 15px 20px 0 0;
      font-size: 30px;
      color: $first-color;
    }
  }

  &--goback {
    border: 1px solid $first-color;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background-color: rgba(18, 146, 80, 0.282);
    position: absolute;
    top: 10px;
    left: 10px;
    justify-content: space-between;
    i {
      margin: 14px 0 0 18px;
      font-size: 35px;
      color: $first-color;
    }
  }
}
