.imgprofil {
  &--img {
    background-color: $first-color;
    width: 250px;
    height: 250px;
    border-radius: 100%;
    position: relative;

    &-dark-circle,
    &-green-circle,
    &-profil-pic {
      background-color: $second-color;
      width: 95%;
      height: 95%;
      border-radius: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &-green-circle {
      background-color: $first-color;
      width: 87%;
      height: 87%;
    }

    &-profil-pic {
      width: 80%;
      height: 80%;
    }
  }

  &--name {
    color: white;
    font-size: 50px;
    margin: 0;
    text-align: center;
  }
}
