.nav-btn {
  background-color: rgba(0, 0, 0, 0.163);
  width: 80%;
  border: 2px solid $first-color;
  border-radius: 5px;
  color: #d9e2f3;
  text-align: center;
  font-size: 35px;
  margin: 10px 0;

  transition: color 0.2s, background-color 0.2s;
  p {
    margin: 10px;
  }
  &:hover,
  &.active {
    background-color: rgba(0, 0, 0, 0.436);
    color: #f4f8ff;
    box-shadow: rgba(206, 206, 206, 0.2) 0px 2px 8px 0px;
  }
}
