$bg-color-form: #e2fff9;

.contact-form {
  width: 600px;
  background-color: $bg-color-form;
  border: 5px solid $first-color;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  height: 90%;
  form {
    width: 100%;
    max-height: 365px;
    height: inherit;
  }
  h3 {
    font-size: 50px;
    margin: 10px 0 30px;
  }
}

.field-item {
  position: relative;
  display: flex;
  width: 100%;
  height: 60px;
  justify-content: center;
  margin: 0;

  &:focus-within,
  &.not-empty {
    .field-item--label {
      transform: scale(0.8);
      top: -15px;
      z-index: 2;
      background-color: $bg-color-form;
      color: grey;
    }
  }

  &--input {
    position: absolute;
    background: none;
    border-radius: 30px;
    padding: 10px;
    border: 2px solid gray;
    z-index: 1;
    min-width: 90%;
    font-size: 20px;

    &:focus {
      border: 2px solid rgb(0, 0, 0);
    }
  }
  textarea {
    height: 100px;
    width: 90%;
    resize: none;
  }

  &--label {
    padding: 0 5px;
    position: absolute;
    font-size: 30px;
    top: 6px;
    margin: 0;
    z-index: 0;
    transition: all 0.3s ease;
  }

  &--errormsg {
    color: red;
    position: absolute;
    bottom: 12px;
    font-size: 17px;
  }

  &.error {
    .field-item--input {
      border: 2px solid red;
    }
  }
}

.form-submit-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  transition: color 0.2s, background-color 0.2s;

  p {
    margin: 10px;
    width: 100px;
    background-color: rgba(0, 0, 0, 0.436);
    border: 2px solid $first-color;
    border-radius: 5px;
    color: #d9e2f3;
    font-size: 35px;
    padding: 5px 15px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.752);
      color: #f4f8ff;
      cursor: pointer;
    }
  }
  &.error {
    p {
      background-image: linear-gradient(
        45deg,
        #ff2b2b 0%,
        #8c1b1b 51%,
        #8e4348 100%
      );
      animation: ErrorShake 0.5s ease;
    }
  }
}

.form-fields-names {
  display: flex;
  width: 100%;
  justify-content: space-around;
  .field-item {
    width: 40%;
    margin: 0;
    input {
      width: 100%;
    }
  }
}

.form-send-thanks {
  padding: 20px;
  svg {
    max-width: 70%;
  }
  &--title {
    font-size: 60px;
  }
  &--msg {
    font-size: 30px;
  }
}

@include mobile-only {
  .contact-form {
    width: 100%;
    height: 100%;
    h3 {
      margin: 5px 0;
    }
  }
}
