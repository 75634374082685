.banner {
  background-color: $first-color;
  width: 400px;
  height: 100%;
  position: fixed;
  left: 30px;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;

  &--close-btn {
    position: absolute;
    z-index: 1001;
    font-size: 40px;
    right: 10%;
    top: 20px;
    color: $first-color;
    display: none;
  }

  &--bg {
    background-color: $second-color;
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
    justify-content: space-between;
  }

  &--medias {
    background-color: rgba(181, 111, 111, 0);
    width: 75%;
    bottom: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    justify-content: space-around;

    &-item {
      background-color: rgba(0, 0, 0, 0.483);
      width: 50px;
      height: 50px;
      margin: 5px;
      border-radius: 100%;
      position: relative;
      transition: color 0.2s, transform 0.2s;

      i {
        position: absolute;
        color: rgba(255, 255, 255, 0.57);
        font-size: 25px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: color 0.2s;
      }

      &:hover {
        transform: scale(1.1);
        background-color: rgb(0, 0, 0);
        cursor: pointer;
        i {
          color: white;
        }
      }
    }
  }

  &--nav {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

@include tablet-only {
  .banner {
    width: 100%;
    height: 100%;
    left: 0;

    &.anime-me {
      animation: animation-banner 0.5s ease-in-out;
    }

    &--close-btn {
      display: block;
    }
  }
}

@keyframes animation-banner {
  0% {
    transform: translate(100%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
