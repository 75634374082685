.scroll-to-top-btn {
  position: fixed;
  bottom: 70px;
  right: 70px;
  font-size: 70px;
  color: rgba(0, 0, 0, 0.296);
  z-index: 10000;

  &:hover {
    color: rgba(0, 0, 0, 0.646);
    cursor: pointer;
  }
}

@include mobile-only {
  .scroll-to-top-btn {
    bottom: 20px;
    right: 20px;
    font-size: 60px;
  }
}
