.title-page {
  width: 100%;
  text-align: center;
  &--text {
    font-size: 50px;
    padding: 10px 0;
    color: $first-color;
    background-color: #00000017;
  }
  &.anime-me {
    animation: anime-title 1s ease-in-out;
  }
}

@keyframes anime-title {
  from {
    transform: translate(-100%);
    opacity: 0;
  }
}
