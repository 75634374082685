.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.653);
  z-index: 1100;
  top: 0;
  left: 0;

  &--close {
    position: absolute;
    right: 30px;
    top: 30px;
    font-size: 40px;
    color: $first-color;
    cursor: pointer;
    z-index: 1200;
    background-color: rgba(0, 0, 0, 0.58);
    padding: 5px 9px;
    text-align: center;
    border-radius: 100%;
    &:hover {
      background-color: rgba(0, 0, 0, 0.728);
    }
  }

  &--content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 98%;
    max-height: 98%;
    z-index: 1150;
  }
}

@include mobile-only {
  .modal--close {
    right: 15px;
    top: 15px;
    font-size: 30px;
  }
}
