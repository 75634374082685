.about-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 50px 30px 200px 0;
  &.reverse {
    flex-direction: row-reverse;
  }

  &--description,
  &--illustration {
    text-align: center;
    height: auto;
    margin-left: 3%;
  }

  &--illustration {
    width: 42%;
    display: flex;
    align-items: center;
    svg {
      width: 100%;
    }
  }

  &--description {
    width: 52%;
    display: flex;
    flex-direction: column;
    border: 2px solid #3cb499;
    border-radius: 34px;
    border-top: 0;
    h4 {
      font-size: 50px;
      border: 2px solid $first-color;
      border-radius: 50px;
      background-color: #3cb49a22;
      color: $second-color;
    }

    p {
      font-size: 25px;
      margin: 40px;
    }
  }
}

.about-box {
  text-align: start;
  margin-bottom: 40px;
  user-select: none;
  width: 90%;
  align-self: center;
  position: relative;
  p {
    margin: 0;
  }

  &.open {
    .about-box {
      &--header {
        border-radius: 25px 25px 0 0;

        i {
          transform: rotateZ(180deg);
        }
      }
    }
  }
  &--header {
    background-color: rgba(255, 166, 0, 0.04);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 25px;
    padding: 2px 5px;
    border: 2px solid rgba(255, 166, 0, 0.6);
    height: 50px;
    &:hover {
      background-color: rgba(255, 166, 0, 0.103);
      cursor: pointer;
      i {
        color: black;
      }
    }
    img {
      max-height: 70%;
      padding: 10px;
    }
    p {
      color: gray;
      font-size: 23px;
    }
    i {
      font-size: 30px;
      color: rgba(0, 0, 0, 0.362);
    }
  }
  &--body {
    position: absolute;
    padding: 10px;
    border: 1px solid orange;
    border-top: none;
    border-radius: 0 0 25px 25px;
    background-color: white;
    z-index: 500;
    p {
      margin: 0;
    }
  }
}

@media screen and (max-width: 1150px) {
  .welcome-page--content {
    padding-right: 0;
  }
  .about-item,
  .about-item.reverse {
    flex-direction: column;
    align-items: center;
    margin: 30px 0 100px 0;
    padding: 0;

    &--illustration {
      margin: 0;
      width: 500px;
    }

    &--description {
      margin: 0;
      width: 90%;
    }
  }
}

@include mobile-only {
  .about-item {
    &--illustration {
      margin: 0;
      width: 80%;
    }

    &--description {
      margin: 0;
      border-radius: 24px;
      width: 98%;
      h4 {
        font-size: 35px;
      }
      p {
        font-size: 22px;
        margin: 15px;
      }
    }
  }
  .about-box {
    width: 98%;
    p {
      margin: 0;
    }
  }
}
