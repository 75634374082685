.welcome-page {
  &--content {
    padding-right: 3%;
    animation: button-top 1s ease-in-out;
  }
  &--nav {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 50px 0 150px;
    animation: button-top 1s ease-in-out;
    p {
      user-select: none;
      font-size: 30px;
      margin: 5px;
      border: 2px solid $first-color;
      padding: 5px 20px;
      border-radius: 30px;
      background-color: #3cb49a22;
      color: $second-color;
      font-weight: 700;
      &:hover {
        background-color: #3cb49a80;
        cursor: pointer;
      }
    }
  }
}
@include mobile-only {
  .welcome-page--nav {
    margin: 20px 0 60px;
    p {
      font-size: 25px;
    }
  }
}
