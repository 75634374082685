/*        Fonts        */
@font-face {
  font-family: "TwCenMTCondensed";
  src: local("TwCenMTCondensed"), url(../assets/fonts/TwCenMTCondensed.ttf);
}
@font-face {
  font-family: "TwCenMTCondensedBold";
  src: local("TwCenMTCondensedBold"),
    url(../assets/fonts/TwCenMTCondensedBold.ttf);
}

/*         Colors         */
$first-color: #3cb499;
$second-color: #3d4f62;

/*         Responsive         */

@mixin desktop-only {
  @media screen and (min-width: 1300px) {
    @content;
  }
}
@mixin tablet-only {
  @media screen and (max-width: 1300px) {
    @content;
  }
}
@mixin mobile-only {
  @media screen and (max-width: 560px) {
    @content;
  }
}

a {
  text-decoration: none;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "TwCenMTCondensedBold", Arial, Helvetica, sans-serif;
  margin: 0;
}

p,
input,
span,
textarea,
a {
  font-family: "TwCenMTCondensed", Arial, Helvetica, sans-serif;
}
* {
  scroll-behavior: smooth;
}

body,
html {
  width: 100%;
  height: 100%;
}

#root {
  padding: 100px 30px 0 430px;
  width: auto;
}

@include tablet-only {
  #root {
    padding: 80px 0 0 0;
  }

  h2 {
    margin: 0;
  }
}
